import React, { useCallback, useEffect, useRef, useState } from "react";
import UnitList from "./UnitList";
import UnitDetail from "./UnitDetail";
import UnitFilter, { MAX, MIN } from "./UnitFilter";
import "./index.scss";
import FloorPlanGallery from "../floor-plan-gallery";
import Gallery from "../gallery";
import { useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import FloorPlanBtn from "../floor-plan-gallery/FloorPlanBtn";
import { useDispatch } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { debounce } from "lodash";
import { WEBSOCKET_CHANNEL, ACTION_NAME, PAGES } from "../../constants/options";
import socket from "../../helper/socket";
import { HotspotWrapper, hotspotMapper, hotspotMapper2 } from "../gallery-landing/components/virtual-tours";
import { AwModal } from "../aw-modal/aw-modal";
import { Equirectangular } from "../gallery-landing/components/equirectangular";
import {virtualTours} from "../gallery-landing/components/virtual-tours";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import { IconArrowThin } from "../svgs/icons";

const UnitExplore = (props) => {
  const {
    handleUnitClick,
    setActiveObjectIds,
    controls,
    refScene,
    isPresentation,
  } = props;
  const dispatch = useDispatch();
  const floorPlanGalleryRef = useRef();
  const [urlVirtual, setUrlVirtual] = useState(virtualTours[0].url[0]);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  useEffect(() => {
    if (isShowVirtualTour) setUrlVirtual(selectedUnit.name === '19A' ? virtualTours[0].url[0] : virtualTours[1].url[0]);
  }, [isShowVirtualTour, selectedUnit]);
  const isShowUnitDetail = useSelector(
    (state) => state.unitExplore.isShowUnitDetail
  );
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );

  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect
  );
  const filterUnitRoomType = useSelector(
    (state) => state.unitExplore.filterUnitRoomType
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability
  );
  const units = useSelector((state) => state.unitExplore.units);
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const prevPage = useSelector((state) => state.home.prevPage);
  const lightActiveSession = useSelector((state) => state.lightswarm.activeSession);

  useEffect(() => {
    if (prevPage === PAGES.VIEW_PAGE) {
      return;
    }
    if (isFiltered()) {
      const unitIds = units.map((item) => item["3d_asset"]?.id);
      const lights = units
        .filter((x) => !!x["3d_asset"]?.light)
        .map((x) => ({
          name: x["3d_asset"]?.light?.name,
          group: x["3d_asset"]?.light?.group,
          unitName: x.name
        }));
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.SET_ACTIVE_OBJECT_IDS, {
          unitIds,
        });
        socket.emitLightUp(lights, lightActiveSession);
      }
      setActiveObjectIds(unitIds);
    } else if (selectedUnit) {
      const unitFiltereds = units.filter((item) => item.id == selectedUnit.id);
      if (unitFiltereds.length) {
        const unitIds = [unitFiltereds[0]["3d_asset"]?.id];
        const lights = !!unitFiltereds[0]["3d_asset"].light
          ? [{
            name: unitFiltereds[0]["3d_asset"].light.name,
            group: unitFiltereds[0]["3d_asset"].light.group,
            unitName: unitFiltereds[0]["name"]
            }]
          : [];
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.SET_ACTIVE_OBJECT_IDS, {
            unitIds,
          });
          socket.emitLightUp(lights, lightActiveSession);
        }
        setActiveObjectIds(unitIds);
      }
    } else {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.SET_ACTIVE_OBJECT_IDS, {
          unitIds: [],
        });
        socket.emitLightUp([], lightActiveSession, { turnAllOff: true });
      }
      setActiveObjectIds([]);
    }
  }, [units]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SET_ACTIVE_OBJECT_IDS) {
          setActiveObjectIds(content?.data?.unitIds);
        }
      });
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SWITCH_HOTSPOT) {
          setUrl(content?.data?.url);
        }
      });
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.STOP_FILM_GALLERY_PAGE) {
          onStopVirtual();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(unitQuery).length !== 0 && isShowUnitList) {
      handleGetUnitFn();
    }
  }, [unitQuery, isShowUnitList]);

  const handleGetUnit = () => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  };

  const handleGetUnitFn = debounce(() => handleGetUnit(), 200);

  useEffect(() => {
    onFilterChange();
  }, [
    filterUnitAspect,
    filterUnitBedroom,
    // filterUnitRoomType,
    filterUnitPrice,
    filterUnitLotSize,
    filterUnitAvailability,
  ]);

  const isFiltered = useCallback(() => {
    return (
      filterUnitAspect ||
      filterUnitBedroom.length ||
      // filterUnitPrice?.max ||
      // filterUnitPrice?.min ||
      // filterUnitRoomType.length ||
      (filterUnitLotSize?.max !== MAX && filterUnitLotSize?.max != 0) ||
      (filterUnitLotSize?.min != 0 && filterUnitLotSize?.min !== MIN) || 
      filterUnitAvailability.length
    );
  }, [filterUnitLotSize, filterUnitAspect, filterUnitBedroom, filterUnitAvailability]);

  const onFilterChange = () => {
    const query = {};
    if (filterUnitAspect) {
      query["aspect[equal]"] = filterUnitAspect.value;
    }

    if (filterUnitBedroom.length) {
      query["bedrooms[in]"] = JSON.stringify(
        filterUnitBedroom.map((item) => item.value)
      );
    }

    if (filterUnitRoomType?.length) {
      query["roomType[in]"] = JSON.stringify(
        filterUnitRoomType.map((item) => item.value)
      );
    }

    if (filterUnitAvailability.length) {
      query["availabilityStatus[in]"] = JSON.stringify(
        filterUnitAvailability.map((item) => item.value)
      );
    }

    // if (filterUnitPrice) {
    //   if (filterUnitPrice.min) {
    //     query["price[gte]"] = filterUnitPrice.min;
    //   }

    //   if (filterUnitPrice.max) {
    //     query["price[lte]"] = filterUnitPrice.max;
    //   }
    // }

    if (filterUnitLotSize) {
      if (filterUnitLotSize.min) {
        query["totalLot[gte]"] = filterUnitLotSize.min;
      }

      if (filterUnitLotSize.max) {
        query["totalLot[lte]"] = filterUnitLotSize.max;
      }
    }

    dispatch(unitExploreAct.reqSetUnitQuery(query));
    // dispatch(unitExploreAct.reqSetSelectedUnit());
  };

  const onStopVirtual = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_FILM_GALLERY_PAGE);
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(reqSetIsTransparent(false));
    setUrlVirtual("");
  };

  const setUrl = (url) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SWITCH_HOTSPOT, {
        url
      });
    }
    setUrlVirtual(url)
  }

  return (
    <>
      <div className={`wrap-sidenav`}>
        <CSSTransition
          in={isShowFilter}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <UnitFilter
            setActiveObjectIds={setActiveObjectIds}
            isTransparent={isTransparent}
            controls={controls}
            refScene={refScene}
            isPresentation={isPresentation}
          />
        </CSSTransition>
        <div
          className={`float-start position-relative ${
            isTransparent ? "invisible" : ""
          }`}
        >
          <CSSTransition
            in={isShowUnitList}
            timeout={500}
            classNames="fade-left"
            unmountOnExit
          >
            <UnitList
              handleUnitClick={handleUnitClick}
              isPresentation={isPresentation}
            />
          </CSSTransition>
        </div>

        {/* <CSSTransition
          in={!!selectedUnit && isShowFloorplan}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <FloorPlanBtn
            floorPlan={selectedUnit?.floorPlan}
            floorPlanGalleryRef={floorPlanGalleryRef}
          />
        </CSSTransition> */}
      </div>

      <CSSTransition
        in={!!selectedUnit && isShowUnitDetail}
        timeout={500}
        classNames="fade-item"
        unmountOnExit
      >
        <div
          className={`wrap-sidenav wrap-sidenav--right ${
            isTransparent ? "invisible" : ""
          }`}
        >
          <UnitDetail isPresentation={isPresentation} />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isShowFloorplan}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <FloorPlanGallery
          floorPlanGalleryRef={floorPlanGalleryRef}
          isPresentation={isPresentation}
        />
      </CSSTransition>

      <TransitionGroup>
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Gallery isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowVirtualTour && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div className="wrap-floor-plan-gallery">
              <AwModal show={isShowVirtualTour}>
                <div className="wrap-nav" style={{ top: isPresentation ? -80 : 0 }}>
                  <p className="unit-name">Residence {selectedUnit?.name}</p>
                  <p className="close-button" onClick={onStopVirtual}>
                    <IconArrowThin style={{ width: 31, marginRight: 4 }} fill={"#B2B2B2"} />
                    CLOSE VIRTUAL TOUR
                  </p>
                </div>
                <HotspotWrapper isPresentation={isPresentation} mapper={selectedUnit?.name === '19A' ? hotspotMapper : hotspotMapper2} setUrl={setUrl} urls={selectedUnit?.name === '19A' ? virtualTours[0].url : virtualTours[1].url}/>
                <Equirectangular url={urlVirtual} isPresentation={isPresentation}/>
                {/* <span className="close-button-down" style={{ bottom: isPresentation ? 20 : 100}} onClick={onStopVirtual}>CLOSE VIRTUAL TOUR</span> */}
              </AwModal>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default UnitExplore;
