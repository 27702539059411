import React, { useState, useEffect, useMemo } from "react";
import iconPlay from "../../../assets/images/ellipse.svg";
import virtual1 from "../../../assets/images/virtual1.webp";
import virtual2 from "../../../assets/images/virtual2.webp";
import virtual3 from "../../../assets/images/virtual3.webp";
import virtual4 from "../../../assets/images/virtual4.webp";
import virtual5 from "../../../assets/images/virtual5.webp";
import virtual6 from "../../../assets/images/virtual6.webp";
import bedroom from "../../../assets/images/bedroom.webp";
import kitchen from "../../../assets/images/kitchen.webp";
import great_room from "../../../assets/images/great_room.webp";
import closet from "../../../assets/images/closet.webp";
import bath from "../../../assets/images/bath.webp";
import thumbnail1 from "../../../assets/images/virtual-tours-thumbnail-1.webp";
import thumbnail2 from "../../../assets/images/virtual-tours-thumbnail-2.webp";
import "../../home-gallery/index.scss";
import "./index.scss";
import { AwModal } from "../../aw-modal/aw-modal";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../constants/options";
import socket from "../../../helper/socket";
import { Equirectangular } from "./equirectangular";
import { IconArrowThin } from "../../svgs/icons";

export const hotspotMapper = {
  6: 'Terrace',
  5: 'Bedroom',
  4: 'Family Room',
  3: 'Kitchen',
  2: 'Great Room',
  1: 'Family Game Room',
}

export const hotspotMapper2 = {
  5: 'Primary Bath',
  4: 'Primary Closet',
  3: 'Great Room',
  2: 'Kitchen',
  1: 'Bedroom',
}

export const virtualTours = [
    {
        url: [virtual1, virtual2, virtual3, virtual4, virtual5, virtual6],
        thumbnail: virtual1,
        name: 'Residence 19A',
        description: 'VIRTUAL TOUR',
        mapper: hotspotMapper
    },
    {
        url: [bedroom, kitchen, great_room, closet, bath],
        thumbnail: bedroom,
        name: 'Residence LPHC',
        description: 'VIRTUAL TOUR',
        mapper: hotspotMapper2
    }
  ]

export const HotspotWrapper = ({urls, setUrl, mapper, isPresentation}) => {
    return <ul style={{ position: 'absolute', zIndex: 1000, top: '50%', transform: 'translate(0%, -50%)', left: 0, paddingLeft: 35, marginBottom: 0 }}>
        {
            urls?.map((url, i) => (
                <div key={i} onClick={() => {
                    setUrl(url)
                }} className="hotspot-wrapper">{mapper[i + 1]}</div>
            ))
        }
    </ul>
}

const VirtualTours = ({ isInfinity, isPresentation }) => {
  const [isShowVirtual, setShowVirtual] = useState(false);
  const [urls, setUrls] = useState([]);
  const [name, setName] = useState("");
  const [urlVirtual, setUrlVirtual] = useState("");
  const tours = useMemo(() => virtualTours.find(tour => tour.name === name), [virtualTours, name]);
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PLAY_FILM_GALLERY_PAGE) {
          handleShowVirtual(content?.data?.i);
        }
        if (content.action === ACTION_NAME.STOP_FILM_GALLERY_PAGE) {
            onStopVirtual();
        }
        if (content.action === ACTION_NAME.SWITCH_HOTSPOT) {
            setUrl(content?.data?.url);
        }
      });
    }
  }, []);
  const handleShowVirtual = (i) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_FILM_GALLERY_PAGE, {
        i,
      });
    }
    setName(i.name)
    setUrls(i.url);
    setUrlVirtual(i.url[0]);
    setShowVirtual(true);
  };
  const onStopVirtual = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_FILM_GALLERY_PAGE);
    }
    setShowVirtual(false);
    setUrlVirtual("");
  };

  const setUrl = (url) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SWITCH_HOTSPOT, {
        url
      });
    }
    setUrlVirtual(url);
  }

  return (
    <div className="films-content">
      {virtualTours.map((i, idx) => {
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity ? "infinity" : ""}`}
            style={{
              backgroundImage: `url(${i.thumbnail}`,
              backgroundPosition: 'center'
            }}
            onClick={() => {handleShowVirtual(i);}}
          >
            <div className="card-films-title">{`${i.name}`}</div>
            <div className="card-films-sub">{i.description}</div>
            <img src={iconPlay} alt="play-icon" />
          </div>
        )
      })}
      {isShowVirtual && (
        // <AwModal show={isShowVirtual} onClose={onStopVirtual}>
        //     <HotspotWrapper setUrl={setUrlVirtual} urls={urls}/>
        //     <Equirectangular url={urlVirtual}/>
        // </AwModal>
        <div className="wrap-floor-plan-gallery">
          <AwModal show={isShowVirtual}>
            <div className="wrap-nav" style={{ top: isPresentation ? -80 : 0 }}>
              <p className="unit-name">{name}</p>
              <p className="close-button" onClick={onStopVirtual}>
                <IconArrowThin style={{ width: 31, marginRight: 4 }} fill={"#B2B2B2"} />
                CLOSE VIRTUAL TOUR
              </p>
            </div>
            <HotspotWrapper setUrl={setUrl} urls={urls} mapper={tours.mapper} isPresentation={isPresentation}/>
            <Equirectangular url={urlVirtual} isPresentation={isPresentation}/>
            {/* <span className="close-button-down" style={{ bottom: isPresentation ? 20 : 100}} onClick={onStopVirtual}>CLOSE VIRTUAL TOUR</span> */}
          </AwModal>
        </div>
      )}
    </div>
  );
};
export default VirtualTours;
